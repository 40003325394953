import { SET_LOADING, SET_SESSION, SET_CLINIC, SET_QRC_TOKEN } from "./constants";

export const setLoading = payload => ({
  type: SET_LOADING,
  payload,
});

export const setSession = payload => ({
  type: SET_SESSION,
  payload,
});

export const setClinic = payload => ({
  type: SET_CLINIC,
  payload,
});

export const setQrcToken = payload => ({
  type: SET_QRC_TOKEN,
  payload,
});
