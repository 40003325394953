import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { configStore } from "../store";
import { theme } from "./theme";

const Home = React.lazy(() => import("../features/home/presentation/pages/Home"));
const Signin = React.lazy(() => import("../features/signin/presentation/pages/Signin"));
const Signup = React.lazy(() => import("../features/signup/presentation/pages/Signup"));
const Calendar = React.lazy(() => import("../features/schedules/presentation/pages/Calendar"));
const Schedules = React.lazy(() => import("../features/schedules/presentation/pages/Schedules"));
const Patients = React.lazy(() => import("../features/patients/presentation/pages/Patients"));
const Suppliers = React.lazy(() => import("../features/suppliers/presentation/pages/Suppliers"));
const Jobs = React.lazy(() => import("../features/jobs/presentation/pages/Jobs"));
const Payments = React.lazy(() => import("../features/payments/presentation/pages/Payments"));
const Cashier = React.lazy(() => import("../features/cashier/presentation/pages/Cashier"));
const Users = React.lazy(() => import("../features/users/presentation/pages/Users"));
const Reports = React.lazy(() => import("../features/reports/presentation/pages/Reports"));
const Entries = React.lazy(() => import("../features/reports/presentation/pages/Entries"));
const Dependents = React.lazy(() => import("../features/reports/presentation/pages/Dependents"));
const CalendarClient = React.lazy(() => import("../features/clients/presentation/pages/Calendar"));
const SchedulesClient = React.lazy(() => import("../features/clients/presentation/pages/Schedules"));
const ScheduleDone = React.lazy(() => import("../features/clients/presentation/pages/ScheduleDone"));
const Welcome = React.lazy(() => import("../features/welcome/presentation/pages/Welcome"));
const Subscription = React.lazy(() => import("../features/subscription/presentation/pages/Subscription"));

const store = configStore();

export const App = () => (
  <Provider store={store}>
    <BrowserRouter>
      <React.Suspense fallback="Loading...">
        <ThemeProvider theme={theme}>
          <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/signin/:clinic" element={<Signin />} />
            <Route path="/signup/:clinic" element={<Signup />} />
            <Route path="/subscription/:clinic" element={<Subscription />} />
            <Route path="/schedule/:clinic" element={<CalendarClient />}>
              <Route path="/schedule/:clinic/:day" element={<SchedulesClient />} />
            </Route>
            <Route path="/schedule/done" element={<ScheduleDone />} />
            <Route path="/:clinic" element={<Home />}>
              <Route index element={<Calendar />} />
              <Route path="schedules" element={<Calendar />}>
                <Route path="/:clinic/schedules/:day" element={<Schedules />} />
              </Route>
              <Route path="patients" element={<Patients />} />
              <Route path="professionals" element={<Suppliers />} />
              <Route path="jobs" element={<Jobs />} />
              <Route path="payments" element={<Payments />} />
              <Route path="cashier" element={<Cashier />} />
              <Route path="reports" element={<Reports />}>
                <Route index path="entries" element={<Entries />} />
                <Route index path="dependents" element={<Dependents />} />
              </Route>
              <Route path="users" element={<Users />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </React.Suspense>
    </BrowserRouter>
  </Provider>
);
