export const colors = {
  primary: {
    main: "#5d80f8",
    dark: "#5194b6",
  },
  secondary: {
    main: "#3abd33",
    dark: "#45a874",
    contrastText: "#DDFFE7",
  },
  red: {
    light: "#FFCDD2",
    dark: "#F44336",
  },
  purple: {
    light: "#F3E5F5",
    dark: "#9C27B0",
  },
  indigo: {
    light: "#E8EAF6",
    dark: "#3F51B5",
  },
  yellow: {
    light: "#fff9c4",
    dark: "#ffeb3b",
  },
  green: {
    light: "#c8e6c9",
    dark: "#4caf50",
  },
  cyan: {
    light: "#b2ebf2",
    dark: "#00bcd4",
  },
  brown: {
    light: "#d7ccc8",
    dark: "#795548",
  },
  grey: {
    light: "#eeeeee",
    dark: "#757575",
  }
};
