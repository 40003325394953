const key = "@persistedState";

const persistState = state => {
  const str = JSON.stringify(state);
  const buffer = btoa(encodeURIComponent(str));
  window.sessionStorage.setItem(key, buffer);
}

export const preloadedState = () => {
  const buffer = window.sessionStorage.getItem(key);
  const str = buffer ? decodeURIComponent(atob(buffer)) : "{}";
  return JSON.parse(str);
}

export const persistReducer = rootReducer => (state, action) => {
  const newState = rootReducer(state, action);
  persistState(newState);
  return newState;
}
