import { SET_JOBS, SET_SUPPLIERS, SET_PAYMENTS } from "./constants";

export const setJobs = payload => ({
  type: SET_JOBS,
  payload,
});

export const setSuppliers = payload => ({
  type: SET_SUPPLIERS,
  payload,
});

export const setPayments = payload => ({
  type: SET_PAYMENTS,
  payload,
});
