import { SET_JOBS, SET_SUPPLIERS, SET_PAYMENTS } from "./constants";

const initialState = {
  jobs: [],
  suppliers: [],
  payments: [],
  months: [
    "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto",
    "Setembro", "Outubro", "Novembro", "Dezembro"
  ],
};

export const cacheReducer = (state = initialState, { type, payload }) => {
  if (type === SET_JOBS) {
    return {
      ...state,
      jobs: [
        ...payload,
      ],
    };
  }

  if (type === SET_SUPPLIERS) {
    return {
      ...state,
      suppliers: [
        ...payload,
      ],
    };
  }

  if (type === SET_PAYMENTS) {
    return {
      ...state,
      payments: [
        ...payload,
      ],
    };
  }

  return state;
}
