import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "./rootReducer";
import { persistReducer, preloadedState } from "./persistReducer";

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ || (() => noop => noop);

export const configStore = () => {
  const middlewares = [
    applyMiddleware(thunk),
  ];

  if (process.env.NODE_ENV === "development") {
    middlewares.push(devTools());
  }

  const enhancers = compose(...middlewares);
  const enhanceReducer = persistReducer(rootReducer);

  return createStore(enhanceReducer, preloadedState(), enhancers);
}
