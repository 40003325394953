import { SET_LOADING, SET_SESSION, SET_CLINIC, SET_QRC_TOKEN } from "./constants";

const initialState = {
  loading: false,
  session: {
    token: "",
    role: "",
    supplierId: "",
    patient: null,
  },
  clinic: {},
  qrcToken: null,
}

export const appReducer = (state = initialState, { type, payload }) => {
  if (type === SET_LOADING) {
    return {
      ...state,
      loading: payload,
    }
  }

  if (type === SET_SESSION) {
    return {
      ...state,
      session: {
        ...state.session,
        ...payload,
      }
    }
  }

  if (type === SET_CLINIC) {
    return {
      ...state,
      clinic: payload,
    }
  }

  if (type === SET_QRC_TOKEN) {
    return {
      ...state,
      qrcToken: payload,
    }
  }

  return state;
}
